<template>
  <div>
    <DialogDashboardEditor
        :opened="dialog"
        :dashboard="dashboard"
        @submit="updateFinished"
        @close="closeDialog"
    >
    </DialogDashboardEditor>

    <DialogDashboardDelete
        :opened="dialogDelete"
        :dashboard="dashboard"
        @submit="deleteFinished"
        @close="closeDialog">
    </DialogDashboardDelete>

    <v-row cols="auto">
      <v-col
          cols="12"
          sm="12"
      >
        <v-btn
            outlined
            color="primary"
            @click="createDashboard()"
        >
          <v-icon
              left
              dark
          >
            mdi-plus
          </v-icon>
          Добавить дэшборд
        </v-btn>
      </v-col>
    </v-row>
    <v-row
        cols="auto"
    >
      <v-col
          v-for="item in dashboards.items"
          :key="item.id"
          cols="12"
          sm="12"
          md="4"
      >
        <v-card
            :id="item.id"
        >
          <v-card-title>
            <p class="ml-4">
              <router-link :to="{name: 'dashboardsItem', params: {id: item.id}}">
                {{ item.title }}
              </router-link>
            </p>
          </v-card-title>

          <v-card-subtitle>
            {{ typesMap[item.type].title }}
            <br/>
            {{ item.endpoint }}
          </v-card-subtitle>

          <v-card-text class="text-xs-center">
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="editDashboard(item)"
            >
              Редактировать
            </v-btn>
            <v-btn
                color="red lighting-2"
                text
                @click="deleteDashboard(item)"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!dashboards.items">
      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar, actions"
        ></v-skeleton-loader>
      </v-col>

      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar, actions"
        ></v-skeleton-loader>
      </v-col>

      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DialogDashboardEditor from '@/components/DialogDashboardEditor'
import DialogDashboardDelete from '@/components/DialogDashboardDelete';
import {roles} from '@/stores/user';
import {types, typesMap} from '@/services/dashboard';

export default {
  name: 'DashboardList',
  components: {
    DialogDashboardDelete,
    DialogDashboardEditor
  },
  data () {
    return {
      attrs: {
        class: 'mb-6',
        elevation: 1
      },
      dashboard: {},
      dashboards: {},
      dialog: false,
      dialogDelete: false,
      errors: {},
      typesMap: typesMap
    }
  },
  async created () {
    this.dashboards = await this.$dashboard.list()
  },
  methods: {
    closeDialog () {
      this.dashboard = {}
      this.dialog = false
      this.dialogDelete = false
    },
    createDashboard () {
      this.dashboard = {
        title: '',
        type: types[0].id,
        visibilityRole: roles[0].id
      }
      this.dialog = true
    },
    editDashboard (dashboard) {
      this.dashboard = Object.assign({}, dashboard)
      this.dialog = true
    },
    deleteDashboard (dashboard) {
      this.dashboard = Object.assign({}, dashboard)
      this.dialogDelete = true
    },
    async deleteFinished () {
      const idx = this.dashboards.items.findIndex(elm => elm.id === this.dashboard.id)
      this.dashboards.items.splice(idx, 1)
      this.closeDialog()
    },
    updateFinished (res) {
      const idx = this.dashboards.items.findIndex(elm => elm.id === res.id)
      if (~idx) {
        this.dashboards.items[idx] = Object.assign({}, res)
      } else {
        this.dashboards.items.push(Object.assign({}, res))
      }
      this.closeDialog()
    }
  }
}
</script>

<style scoped>

</style>