import Vue from 'vue'
import {setInterval} from "timers-browserify";

const title = document.title
let intervalId
let prevCount = 0

export const counters = new Vue.observable({
    newSources: [],
    newRequests: []
})

export const actions = Object.freeze({
    fill (data) {
        counters.newSources = data.counters
        counters.newRequests = []
        for (const sourceId in data.requests) {
            counters.newRequests = counters.newRequests.concat(data.requests[sourceId])
        }
        startBlink(counters.newSources.all)
    },
})

export const getters = Object.freeze({
    newSources: () => counters.newSources,
    newRequests: () => counters.newRequests
})

function startBlink(count) {
    if (prevCount === count) {
        return
    }
    prevCount = count
    stopBlink(!count)
    if (!count) {
        return;
    }
    intervalId = setInterval(() => {
        if (document.title !== title) {
            document.title = title
        } else {
            document.title = Vue.filter('wordend')(count, ['новая заявка', 'новые заявки', 'новых заявок'])
        }
    }, 1000)
}

function stopBlink(showTitle) {
    if (intervalId) {
        clearInterval(intervalId)
        intervalId = null
        if (showTitle) {
            document.title = title
        }
    }
}