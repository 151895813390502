<template>
  <div class="w-1/4 mr-4" v-if="!$vuetify.breakpoint.mobile">
    <p class="text-subtitle-2 pb-2">
      <v-row no-gutters dense>
        <v-col>
          Фильтры
        </v-col>
        <v-col auto>
          <v-btn
              v-if="selectedSize"
              color="error"
              text
              x-small
              @click="selectClear">
            очистить
          </v-btn>
        </v-col>
      </v-row>
    </p>
    <div v-if="resolved">
      <FacetSection
          v-for="(values, id) in data"
          v-bind:key="id"
          :id="id"
          :values="values"
      ></FacetSection>
    </div>
    <div v-else>
      <v-skeleton-loader
        type="text"
      >
      </v-skeleton-loader>
      <v-skeleton-loader
          type="text"
      >
      </v-skeleton-loader>
      <v-skeleton-loader
          type="text"
      >
      </v-skeleton-loader>
      <v-skeleton-loader
          type="text"
      >
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import FacetSection from '@/components/FacetSection'
import {actions, getters} from '@/stores/facets'
export default {
  name: 'Facets',
  components: {FacetSection},
  computed: {
    ...getters
  },
  methods: {
    ...actions
  }
}
</script>

<style scoped>

</style>