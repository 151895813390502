<template>
  <div>
    <v-overlay
        opacity="0.8"
        :value="show"
    >
      <div class="wrapper ma-10">
        <v-btn
            icon
            class="close-btn"
            @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="image-wrapper">
          <img :src="src" />
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Viewer',
  data () {
    return {}
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    src: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss">
.v-overlay__content {
  max-height: 100%;
}
</style>

<style scooped lang="scss">
.close-btn {
  position: absolute;
  right: 0;
  top: -10px;
}
.image-wrapper {
  margin: auto;
  display: block;

  img {
    max-width: 100%;
    max-height: 90vh;
    display: block;
    margin: 0 auto;
  }
}
</style>