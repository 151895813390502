<template>
  <div>
    <DialogCustomerSubscriptions
      :opened="subscriptionsDialog"
      :subscriptions="request.subscriptions"
      @close="subscriptionsDialog = false"
    ></DialogCustomerSubscriptions>
    <v-row>
      <v-col
          v-if="request.subscriptions && request.subscriptions.length"
      >
        <v-btn
            class="lighten-4"
            text
            x-small
            :class="{'green': request.subscriptions[0].active, 'red': !request.subscriptions[0].active}"
            @click="openSubscriptions"
        >
          <v-icon
              left
          >
            mdi-format-list-bulleted
          </v-icon>
          {{ subscriptionTypes[request.subscriptions[0].type].title }} до {{ request.subscriptions[0].tsExpire | date }}
        </v-btn>
      </v-col>
      <v-col
          v-for="stealType of stealTypes"
          :key="stealType.id"
      >
        <v-btn
            text
            x-small
            @click="stealSession(stealType.id)"
        >
          <v-icon
              left
          >
            {{ stealType.icon }}
          </v-icon>
          {{ stealType.title }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {apiPrefix, stealSessionTypes, subscriptionTypes} from "@/constants/constants";
import DialogCustomerSubscriptions from "@/components/DialogCustomerSubscriptions";

export default {
  name: 'SupportRequestAdminActions',
  components: {DialogCustomerSubscriptions},
  data () {
    return {
      stealTypes: stealSessionTypes,
      subscriptionsDialog: false,
      subscriptionTypes: subscriptionTypes
    }
  },
  methods: {
    openSubscriptions: function () {
      this.subscriptionsDialog = true
    },
    stealSession: function (typeId) {
      window.open(
          `${apiPrefix}/requests/session/steal?sourceId=${this.request.sourceId}&customerId=${this.request.customerId}&resource=${typeId}`,
          '_blank'
      )
    }
  },
  props: {
    request: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>