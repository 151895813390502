import axios from 'axios'
import Vue from 'vue'

export const roles = [{
    id: 'superadmin',
    title: 'Супер админ'
}]

export const rolesMap = roles.reduce((map, obj) => {
    map[obj.id] = obj
    return map
}, {})

export const user = new Vue.observable({
    authorized: false,
    data: {
        vkInfo: {}
    },
    resolved: false,
})

export const actions = {
    askData () {
        return axios.get('/api/user/info')
            .then(data => {
                user.data = data
                user.authorized = true
            })
            .finally(() => {
                user.resolved = true
            })
    }
}

export const getters = {
    isAuthorized: () => user.resolved && user.authorized,
    isUnauthorized: () => user.resolved && !user.authorized,
    avatar: () => user.data.vkInfo.photo,
    fullName: () => user.data.vkInfo.fullName
}