<template>
  <div class="list-wrapper">
    <draggable
        :disabled="!editable"
        :forceFallback="true"
        :list="attachments"
    >
      <MessageAttachmentItem
          v-for="(attachment, idx) in attachments"
          v-bind:key="idx"
          :editable="editable"
          :item="attachment"
          @delete="deleteAttachment(idx)"
          @image-click="openViewer"
      ></MessageAttachmentItem>
    </draggable>

    <Viewer
        :show="showViewer"
        :src="currentImageUrl"
        v-on:close="closeViewer"
    ></Viewer>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MessageAttachmentItem from '@/components/MessageAttachmentItem';
import Viewer from '@/components/Viewer';
export default {
  name: 'MessageAttachmentList',
  components: {draggable, Viewer, MessageAttachmentItem},
  data () {
    return {
      currentImageUrl: '',
      showViewer: false
    }
  },
  methods: {
    closeViewer () {
      this.showViewer = false
      this.currentImageUrl = ''
    },
    deleteAttachment (idx) {
      this.$emit('delete', idx)
    },
    openViewer (imageUrl) {
      this.showViewer = true
      this.currentImageUrl = imageUrl
    }
  },
  props: {
    attachments: {
      required: true,
      type: Array
    },
    editable: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.list-wrapper {
  margin: 0 5rem;
}
</style>