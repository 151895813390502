import axios from 'axios'

const dashboardApi = '/api/analytics/dashboards'
export const types = [{
    id: 'line',
    title: 'Линейный график'
}]

export const typesMap = types.reduce((map, obj) => {
    map[obj.id] = obj
    return map
}, {})

class Dashboard {
    list () {
        return axios.get(dashboardApi)
    }
    item (id) {
        return axios.get(`${dashboardApi}/item`, {params: {id}})
    }
    create (dashboard) {
        return axios.post(`${dashboardApi}/create`, dashboard)
    }
    update (dashboard) {
        return axios.post(`${dashboardApi}/update`, dashboard)
    }
    delete (dashboardId) {
        return axios.post(`${dashboardApi}/delete`, {id: dashboardId})
    }
}

export const dashboardService = new Dashboard()