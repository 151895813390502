<template>
  <div
      class="message"
      :class="{'blue lighten-5' : !item.isRead, 'blue lighten-4': selected, 'reply': reply, 'last': item.last}"
      @click="$emit('selectToggle', item.id)"
      @mouseover="mouseover"
      @mouseleave="mouseleave"
  >
    <v-icon
        v-if="hover || selected"
        class="check-indicator text--lighten-2"
        color="primary"
    >
      mdi-check-circle-outline
    </v-icon>
    <v-list-item>
      <v-list-item-avatar class="avatar">
        <v-img :src="customer && customer.photo"></v-img>
        <img v-if="community" class="community-preview" :src="community.photo"/>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-html="customer && customer.fullName"></v-list-item-title>
        <v-list-item-action-text
            v-if="$vuetify.breakpoint.mobile"
            class="text-xs"
        >
          {{item.date | date}}
        </v-list-item-action-text>
        <v-list-item-subtitle class="message-content">
          <span v-html="item.text"></span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                  v-if="item.updateTime"
                  v-bind="attrs"
                  v-on="on"
                  class="indicator-update"
              > (ред.)</span>
            </template>
            <span>изменено {{item.updateTime | date}}</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text 
            v-if="!$vuetify.breakpoint.mobile"
        >
          {{item.date | date}}
        </v-list-item-action-text>
        <v-row
            dense
            no-gutters
        >
          <v-col
              v-if="canEdit()"
              class="px-1"
          >
            <v-btn
                icon
                x-small
                @click="doAction($event,'edit')"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
          <v-col
              v-if="canEdit()"
              class="px-1"
          >
            <v-btn
                icon
                x-small
                @click="doAction($event,'delete')"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col
              v-if="canReply()"
              class="px-1"
          >
            <v-btn
                icon
                x-small
                @click="doAction($event, 'reply')"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn>
          </v-col>
          <v-col
              v-if="!reply"
              class="px-1"
          >
            <v-btn
                icon
                color="primary"
                x-small
                @click="doAction($event, 'forward')"
            >
              <v-icon>mdi-forward</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <MessageAttachmentList
        :attachments="item.attachments"
    ></MessageAttachmentList>
    <div
        :key="m.id"
        v-for="m in item.forwardedMessages"
        :class="{'replied': !reply}"
    >
      <SupportRequestMessagesItem
          reply
          :item="m"
          :profiles="profiles"
      ></SupportRequestMessagesItem>
    </div>
  </div>
</template>

<script>
import MessageAttachmentList from '@/components/MessageAttachmentList';
export default {
  name: 'SupportRequestMessagesItem',
  components: {MessageAttachmentList},
  data () {
    return {
      community: this.item.adminAuthorId ? this.profiles[this.item.fromId] : null,
      customer: this.profiles[this.item.adminAuthorId] || this.profiles[this.item.fromId],
      hover: false
    }
  },
  methods: {
    canEdit () {
      return this.item.isEditable && !this.disabled && this.hover;
    },
    canReply () {
      return !this.disabled && this.hover && this.item.fromId > -1 && !this.item.adminAuthorId
    },
    doAction (event, name) {
      event.stopPropagation()
      if (name === 'forward') {
        window.open(this.item.url, '_blank')
      } else {
        this.$emit(name, this.item)
      }
    },
    mouseover () {
      if (this.reply) {
        return
      }
      this.hover = true
    },
    mouseleave () {
      if (this.reply) {
        return
      }
      this.hover = false
    }
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    selected: {
      default: false,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    },
    profiles: {
      required: true,
      type: Object
    },
    reply: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shared.scss';
.replied {
  border-left: 1px solid #CFD8DC;
  margin-left: 50px;
}
.avatar {
  @include avatar-overflow-visible();
}
.community-preview {
  @include author-preview();
}
.indicator-update {
  font-size: 12px;
}
.message {
  cursor: pointer;
  position: relative;
}
.message-content {
  -webkit-line-clamp: unset!important;
}
.check-indicator {
  font-size: 16px;
  position: absolute;
  top: 5px;
  left: 5px;
}
</style>