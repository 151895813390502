import axios from 'axios'
import Vue from 'vue'
import { apiPrefix } from '@/constants/constants';

export const sources = new Vue.observable({
    data: [],
    resolved: false
})

export const actions = {
    askData () {
        return axios.get(`${apiPrefix}/sources`)
            .then(data => {
                const allIds = []
                for (const source of data.items) {
                    allIds.push(source.id)
                    source.ids = [source.id]
                }
                sources.data = data.items
                sources.data.unshift({
                    id: 'all',
                    ids: allIds,
                    title: 'Все'
                })
            })
            .finally(() => {
                sources.resolved = true
            })
    },
    getIdsById (id) {
        return (sources.data.find(elm => elm.id === +id) || {}).ids
    }
}

export const getters = {
    resolved: () => sources.resolved,
    sources: () => sources.data
}