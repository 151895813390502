export default class StoreItem {
    additionalFields = []
    data
    error
    resolved = false
    getters = {
        data: () => this.data,
        error: () => this.error,
        resolved: () => this.resolved
    }

    constructor (additionalFields) {
        this.additionalFields = additionalFields
        this._initAdditionalFields({}, true)
    }

    fillError (err) {
        this.error = err && err.error ? err.error.message : err
        this.setResolve(true)
    }

    fillFromRs (rs, item) {
        this._fillFields(rs, item)
        this._initAdditionalFields(rs)
        this.setResolve(true)
    }

    setResolve (resolve) {
        this.resolved = resolve
        if (!resolve) {
            this._clearFields()
            this._initAdditionalFields({})
        }
    }

    _clearFields () {
        this.error = null
        this.data = null
    }

    _fillFields (rs, item) {
        this.data = item
    }

    _initAdditionalFields(from, initGetters) {
        for (const field of this.additionalFields) {
            this[field] = from[field]
            if (initGetters) {
                this.getters[field] = () => this[field]
            }
        }
    }
}