<template>
  <section>
    <v-app v-if="initialResolved" id="app">
      <Header></Header>
      <Menu v-if="!$vuetify.breakpoint.mobile"></Menu>
      <MenuMobile v-if="$vuetify.breakpoint.mobile"></MenuMobile>
      <v-main>
        <v-container fluid>
          <router-view/>
        </v-container>
      </v-main>
    </v-app>

    <v-app v-else>
      <div class="text-center ma-5">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
    </v-app>
  </section>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';
import MenuMobile from '@/components/MenuMobile';
import {getters as sourceGetters} from '@/stores/sources'
import {getters as tagGetters} from '@/stores/tags'
import {getters as userGetters} from '@/stores/user'

export default {
  name: 'App',

  components: {
    Header,
    Menu,
    MenuMobile
  },

  computed: {
    initialResolved: function () {
      return sourceGetters.resolved() && tagGetters.resolved() || userGetters.isUnauthorized()
    }
  },

  data: () => ({
    //
  }),
};
</script>
