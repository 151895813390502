import axios from 'axios'
import Vue from 'vue'
import { apiPrefix, limitListItems } from '@/constants/constants';
import StoreList from '@/classes/StoreList';
import StoreItem from '@/classes/StoreItem';
const requestsApiPrefix = `${apiPrefix}/requests`
const cancel = {
    requests: null
}

function _markLastMessage (rs) {
    if (rs.items.length) {
        rs.items[rs.items.length - 1].last = true
    }
}

function cancelRq (cancelLink) {
    if (cancelLink) {
        cancelLink('Operation canceled due to new request.')
    }
}

export const support = new Vue.observable({
    request: new StoreItem(['profiles', 'source']),
    requests: new StoreList(limitListItems, ['profiles']),
    messages: new StoreList(limitListItems, ['profiles'])
})

export const actions = {
    requests: {
        askData (page, filter, facets) {
            cancelRq(cancel.requests)
            support.requests.setResolve(false)
            const rq = support.requests.getRqData(page, filter, {facets})
            return axios.post(requestsApiPrefix, rq, {
                cancelToken: new axios.CancelToken(c => {
                    cancel.requests = c
                })
            })
                .then(data => {
                    support.requests.fillFromRs(data)
                    return data
                })
                .catch((err) => {
                    support.requests.fillError(err)
                    throw err
                })
        },
        silentAsk (page, filter, facets) {
            cancelRq(cancel.requests)
            const rq = support.requests.getRqData(page, filter, {facets})
            return axios.post(requestsApiPrefix, rq, {
                cancelToken: new axios.CancelToken(c => {
                    cancel.requests = c
                })
            })
                .then(data => {
                    support.requests.fillFromRs(data)
                    return data
                })
        }
    },
    request: {
        askData (id) {
            support.request.setResolve(false)
            return axios.get(`${requestsApiPrefix}/item`, {params: {id}})
                .then(data => {
                    support.request.fillFromRs(data, data.request)
                    if (!support.request.data.tags) {
                        support.request.data.tags = []
                    }
                    if (!getters.request.customer().tags) {
                        getters.request.customer().tags = []
                    }
                })
                .catch((err) => support.request.fillError(err))
        },
        updateDifficultRate (id, difficultRate) {
            return axios.post(`${requestsApiPrefix}/difficultRate/update`, {id, difficultRate})
                .then(() => {
                    if (support.request.data.id !== id) {
                        return;
                    }
                    support.request.data.difficultRate = difficultRate
                })
        },
        updateStatus (id, status) {
            return axios.post(`${requestsApiPrefix}/status/update`, {id, status})
                .then(() => {
                    if (support.request.data.id !== id) {
                        return;
                    }
                    support.request.data.status = status
                })
        },
        updateCustomerTags (id, tags) {
            return axios.post(`${apiPrefix}/customers/tags/update`, {id, tags})
                .then(() => {
                    if (support.request.data.customerId !== id) {
                        return;
                    }
                    support.request.profiles[support.request.data.customerId].tags = tags
                })
        },
        updateRequestTags (id, tags) {
            return axios.post(`${requestsApiPrefix}/tags/update`, {id, tags})
                .then(() => {
                    if (support.request.data.id !== id) {
                        return;
                    }
                    support.request.data.tags = tags
                })
        }
    },
    messages: {
        askData (requestId, page, filter) {
            support.messages.setResolve(false)
            return axios.post(`${requestsApiPrefix}/messages`, support.messages.getRqData(page, filter, {requestId}))
                .then(data => {
                    _markLastMessage(data)
                    support.messages.fillFromRs(data)
                })
                .catch(err => support.messages.fillError(err))
        },
        appendData () {
            support.messages.setProcessAppend(true)
            return axios.post(`${requestsApiPrefix}/messages`, support.messages.getRqDataNextPortion())
                .then(data => {
                    _markLastMessage(data)
                    support.messages.appendFromRs(data, false)
                })
                .catch(err => support.messages.fillError(err))
        },
        clearData () {
            support.messages.data = []
        },
        delete (data) {
            return axios.post(`${requestsApiPrefix}/messages/delete`, data)
                .then(data => {
                    const msgIdx = support.messages.data.findIndex(elm => elm.id === data.id)
                    if (msgIdx > -1) {
                        support.messages.data.splice(msgIdx, 1)
                    }
                })
        },
        send (data) {
            return axios.post(`${requestsApiPrefix}/messages/send`, data)
                .then(data => {
                    support.messages.data.push(data)
                })
        },
        silentAsk () {
            return axios.post(`${requestsApiPrefix}/messages`, support.messages.getRqDataAllPortion())
                .then(data => {
                    support.messages.appendFromRs(data, true)
                })
        },
        update (data) {
            return axios.post(`${requestsApiPrefix}/messages/update`, data)
                .then(data => {
                    const msgIdx = support.messages.data.findIndex(elm => elm.id === data.id)
                    if (msgIdx > -1) {
                        support.messages.data[msgIdx] = data
                    }
                })
        }
    }
}

export const getters = {
    messages: {
        ...support.messages.getters,
        processAppend: () => support.messages.processAppend
    },
    request: {
        ...support.request.getters,
        customer: () => support.request.profiles[support.request.data.customerId],
        executor: () => support.request.profiles[support.request.data.executorId]
    },
    requests: {
        ...support.requests.getters
    }
}