<template>
  <v-row
      v-if="forwardedMessages.length"
      dense
      no-gutters
  >
    <v-col>
      <div class="content">
        <div>Пересланные сообщения</div>
        <div>{{ forwardedMessages.length }} шт.</div>
      </div>
    </v-col>
    <v-col
        cols="auto"
    >
      <slot name="actions"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MessageForwardedList',
  props: {
    forwardedMessages: {
      default: () => [],
      type: Array
    }
  }
}
</script>

<style scoped>
.content {
  border-left: 2px solid #CFD8DC;
  padding-left: 10px;
}
</style>