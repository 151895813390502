<template>
  <div>
    <v-sheet
        v-if="!resolved"
    >
      <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar"
      ></v-skeleton-loader>
    </v-sheet>

    <div v-else>
      <v-card
          v-if="data"
          class="resizable mx-auto"
          elevation="1"
      >
        <v-card-text
            class="breadcrumb"
            :class="{ 'mobile': $vuetify.breakpoint.mobile }"
        >
          <v-btn
              icon
              small
              :to="{name: 'support'}"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-chip
              v-if="data && !$vuetify.breakpoint.mobile"
              class="ml-2"
              color="primary"
              small
          >
            {{ source.title }}
          </v-chip>

          <div v-if="$vuetify.breakpoint.mobile">
            <v-avatar size="45">
            <img
                :src="customer.photo"
              >
            </v-avatar>
            <span class="ml-2 title">{{ customer.fullName }}</span>
          </div>
        </v-card-text>
        <v-card-title v-if="!$vuetify.breakpoint.mobile" >
          <v-avatar size="56">
            <img
                :src="customer.photo"
            >
          </v-avatar>
          <p class="ml-3" v-if="!$vuetify.breakpoint.mobile">
            {{ customer.fullName }}
            <SupportRequestStatus
                :disabled="statusChangeProcess"
                :status="data.status"
                v-on:change="statusChanged"
            ></SupportRequestStatus>
            <SupportRequestDifficultRate
                :disabled="difficultRateChangeProcess"
                :difficultRate="data.difficultRate"
                v-on:change="difficultRateChanged"
            ></SupportRequestDifficultRate>
            <SupportRequestAdminActions
                :request="data"
            ></SupportRequestAdminActions>
            <TagSelector
                label="Тэги клиента"
                :selected="customer.tags"
                @select-toggle="customerTagSelectToggle"
            ></TagSelector>
            <TagSelector
                label="Тэги заявки"
                :selected="data.tags"
                @select-toggle="requestTagSelectToggle"
            ></TagSelector>
          </p>
        </v-card-title>

        <v-card-text v-if="!$vuetify.breakpoint.mobile">
          <v-btn
              color="primary"
              outlined
              x-small
              @click="tagEditorOpen"
          >
            <v-icon
                left
                x-small
            >
              mdi-pencil
            </v-icon>
            Настроить тэги
          </v-btn>
        </v-card-text>
      </v-card>

      <Info
          v-if="error"
          type="error"
          :message="error"
      ></Info>

      <SupportRequestMessages
          v-else
          :requestId="requestId"
      ></SupportRequestMessages>

      <v-snackbar
          v-model="snackbar"
          color="error"
          multi-line
      >
        {{ updateError }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
      <TagEditor
          :opened="tagEditor"
          v-on:close="tagEditorClose"
      ></TagEditor>
    </div>
  </div>
</template>

<script>
import {actions, getters} from '@/stores/support'
import Info from '@/components/Info'
import SupportRequestMessages from '@/components/SupportRequestMessages'
import SupportRequestStatus from '@/components/SupportRequestStatus';
import TagEditor from '@/components/TagEditor';
import TagSelector from '@/components/TagSelector';
import SupportRequestDifficultRate from "@/components/SupportRequestDifficultRate";
import SupportRequestAdminActions from "@/components/SupportRequestAdminActions";

export default {
  name: 'SupportRequest',
  components: {
    SupportRequestAdminActions,
    SupportRequestDifficultRate, TagSelector, TagEditor, Info, SupportRequestStatus, SupportRequestMessages},
  computed: {
    ...getters.request
  },
  data () {
    return {
      difficultRateChangeProcess: false,
      requestId: null,
      snackbar: false,
      statusChangeProcess: false,
      tagEditor: false,
      updateError: null
    }
  },
  created () {
    this.requestId = this.$route.params.requestId
    actions.request.askData(this.requestId)
  },
  methods: {
    tagEditorClose () {
      this.tagEditor = false
    },
    tagEditorOpen () {
      this.tagEditor = true
    },
    tagToggle (tags, tagId) {
      const idx = tags.indexOf(tagId);

      if (idx > -1) {
        tags.splice(idx, 1);
      } else {
        tags.push(tagId);
      }
    },
    customerTagSelectToggle (tagId) {
      this.tagToggle(this.customer.tags, tagId)
      actions.request.updateCustomerTags(this.requestId, this.customer.tags)
    },
    requestTagSelectToggle (tagId) {
      this.tagToggle(this.data.tags, tagId)
      actions.request.updateRequestTags(this.requestId, this.data.tags)
    },
    statusChanged (newStatus) {
      if (this.data.status === newStatus) {
        return
      }
      this.snackbar = false
      this.updateError = null
      this.statusChangeProcess = true
      actions.request.updateStatus(this.requestId, newStatus)
          .catch(err => {
            this.updateError = err
            this.snackbar = true
          })
          .finally(() => this.statusChangeProcess = false)
    },
    difficultRateChanged (newRate) {
      this.snackbar = false
      this.updateError = null
      this.difficultRateChangeProcess = true
      actions.request.updateDifficultRate(this.requestId, newRate)
          .catch(err => {
            this.updateError = err.error.message
            this.snackbar = true
          })
          .finally(() => this.difficultRateChangeProcess = false)
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  padding-bottom: 0;
}
.breadcrumb.mobile {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>