<template>
  <div>
    <v-row
        dense
        no-gutters
    >
      <v-col>
        <v-text-field
            clearable
            hide-details
            :disabled="disabled"
            :label="`${text} и нажмите enter...`"
            v-model="q"
            v-on:keyup.enter="search"
            @click:clear="clear"
        ></v-text-field>
      </v-col>
      <v-col
          align-self="end"
          cols="auto"
      >
        <slot name="actions"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
let prevQ
export default {
  name: 'Search',
  created () {
    this.q = this.$route.query.q
    prevQ = this.q
    this._emitQueryChange()
  },
  data () {
    return {
      q: ''
    }
  },
  methods: {
    clear () {
      this.q = ''
      this.search()
    },
    search () {
      if (this.q === prevQ) {
        return
      }
      this.$router.push({query: {q: this.q}})
    },
    _emitQueryChange () {
      prevQ = this.q
      this.$emit('query-changed', this.q)
    }
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    text: {
      required: true,
      type: String
    }
  },
  watch: {
    '$route.query.q': function (val) {
      this.q = val
      this._emitQueryChange()
    }
  }
}
</script>

<style scoped>

</style>