<template>
  <div>
    <Search
        class="resizable"
        :disabled="!resolved"
        v-on:query-changed="search"
        text="Введите текст для поиска"
    >
      <template
          v-if="selectedCount"
          v-slot:actions
      >
        <v-btn
            class="ml-2"
            color="primary"
            small
            text
            @click="replyToSelectedMessages"
        >
          Ответить
        </v-btn>
      </template>
    </Search>

    <Info
        v-if="error"
        type="error"
        :message="error"
    ></Info>

    <v-sheet
        v-if="!resolved"
        class="pa-3"
    >
      <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-three-line"
      ></v-skeleton-loader>

      <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-three-line"
      ></v-skeleton-loader>

      <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-three-line"
      ></v-skeleton-loader>
    </v-sheet>

    <v-list
        v-else
        class="message-list"
        :style="{ height: `calc(100vh - ${heightOffset}px)` }"
        three-line
    >
      <div class="text-center">
        <v-btn
            v-if="hasPages"
            text
            color="primary"
            :disabled="processAppend"
            :loading="processAppend"
            @click="appendData"
        >
          загрузить сообщения
        </v-btn>
      </div>

      <template v-for="item in data">
        <SupportRequestMessagesItem
            :disabled="sendProcess"
            :item="item"
            :key="item.id"
            :profiles="profiles"
            :selected="selectedIds[item.id]"
            @delete="deleteMessage"
            @edit="editMessage"
            @reply="replyMessage"
            @selectToggle="selectToggle"
        ></SupportRequestMessagesItem>
      </template>
    </v-list>

    <MessageEditor
        class="resizable"
        :content="editableContent"
        :disabled="sendProcess"
        @attachmentAdd="attachmentAdd"
        @attachmentDelete="attachmentDelete"
        @cancel="clearEditData"
        @clearForwardedMessages="clearForwardedMessages"
        @delete="deleteMessage"
        @send="sendMessage"
        @editMode="searchAndEditMessage"
        @update="updateMessage"
    ></MessageEditor>
  </div>
</template>

<script>
import {actions, getters} from '@/stores/support'
import Info from '@/components/Info'
import Search from '@/components/Search'
import SupportRequestMessagesItem from '@/components/SupportRequestMessagesItem';
import MessageEditor from '@/components/MessageEditor';
import {wsEvents, wsService} from '@/services/ws';
let currentQuery
let resizeObserver

export default {
  name: 'SupportRequestMessages',
  components: {MessageEditor, Info, Search, SupportRequestMessagesItem},
  computed: {
    ...getters.messages
  },
  data () {
    return {
      editableContent: null,
      heightOffset: 0,
      page: 1,
      selectedCount: 0,
      selectedIds: {},
      sendProcess: false
    }
  },
  created () {
    this.clearEditData()
  },

  mounted() {
    const elms = document.querySelectorAll('.resizable')
    const padding = parseInt(window.getComputedStyle(document.querySelector('.container')).paddingTop)
    resizeObserver = new ResizeObserver(() => {
      this.heightOffset = padding * 2
      for (const e of elms) {
        this.heightOffset += e.offsetHeight
      }
    })
    for (const e of elms) {
      resizeObserver.observe(e)
    }
    wsService.bus.$on(wsEvents.supportRequestsUpdate, data => {
      if (this.requestId === data.requestId) {
        actions.messages.silentAsk()
      }
    })
  },
  beforeDestroy() {
    actions.messages.clearData()
    resizeObserver.disconnect()
    wsService.bus.$off(wsEvents.supportRequestsUpdate)
  },
  methods: {
    attachmentAdd (attachment) {
      if (!this.editableContent.attachments) {
        this.editableContent.attachments = []
      }
      this.editableContent.attachments.push(attachment)
      this.editableContent = {...this.editableContent}
    },
    attachmentDelete (idx) {
      this.editableContent.attachments.splice(idx, 1)
      this.editableContent = {...this.editableContent}
    },
    askData () {
      actions.messages.askData(this.requestId, this.page, {q: currentQuery}).then(this._scrollToLastMessage)
    },
    appendData () {
      actions.messages.appendData().then(this._scrollToLastMessage)
    },
    clearForwardedMessages () {
      delete this.editableContent.forwardedMessages
      this.editableContent = {...this.editableContent}
    },
    editMessage (msg) {
      if (!msg) {
        return
      }
      this.editableContent = {...msg}
    },
    replyMessage (msg) {
      this.selectClear()
      this.editableContent = {
        forwardedMessages: [msg.id],
        text: ''
      }
    },
    replyToSelectedMessages () {
      const messages = []
      for (const id in this.selectedIds) {
        if (this.selectedIds[id]) {
          messages.push(this.data.find(elm => elm.id === id))
        }
      }
      this.selectClear()
      this.editableContent = {
        forwardedMessages: messages,
        text: ''
      }
    },
    search (q) {
      currentQuery = q
      this.askData()
    },
    searchAndEditMessage () {
      this.editMessage(this.data.slice().reverse().find(elm => !elm.isEditable))
    },
    selectClear () {
      this.selectedIds = {}
      this.selectedCount = 0
    },
    selectToggle (itemId) {
      this.selectedIds[itemId] = !this.selectedIds[itemId]
      this.selectedIds = {...this.selectedIds}
      this.selectedCount = this.selectedIds[itemId] ? this.selectedCount + 1 : this.selectedCount - 1
    },
    deleteMessage (msg) {
      this.sendProcess = true
      actions.messages.delete({messageId: msg.id})
          .finally(() => this.clearEditData())
    },
    sendMessage (data) {
      this.sendProcess = true
      data.requestId = this.requestId
      if (data.forwardedMessages) {
        data.forwardedMessageIds = data.forwardedMessages.map(elm => elm.id)
        delete data.forwardedMessages
      }
      actions.messages.send(data)
        .finally(() => this.clearEditData())
    },
    updateMessage (data) {
      this.sendProcess = true
      data.messageId = data.id
      actions.messages.update(data)
          .finally(() => this.clearEditData())
    },
    clearEditData () {
      this.editableContent = {
        text: ''
      }
      this.sendProcess = false
    },
    _scrollToLastMessage () {
      const container = this.$el.querySelector('.message-list')
      const lastMessage = container.querySelector('.last')
      lastMessage.scrollIntoView({block: 'start'})
    }
  },
  props: {
    requestId: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>
.message-list {
  overflow-y: scroll;
}
</style>