<template>
  <v-alert
      dark
      dense
      text
      :type="type"
      :icon="icon"
  >
    {{message}}
  </v-alert>
</template>

<script>
export default {
  name: 'Info',
  props: {
    icon: {
      default: 'mdi-information-outline',
      type: String
    },
    message: {
      required: true,
      type: String
    },
    type: {
      default: 'info',
      type: String
    },
  }
}
</script>

<style scoped>

</style>