import Vue from 'vue'

const prefixKey = 'filters.'
const aggregateByPrefix = `${prefixKey}.aggregate.by`
const datesFromPrefix = `${prefixKey}.dates.from`
const datesToPrefix = `${prefixKey}.dates.to`
const sourceIdPrefix = `${prefixKey}.source.id`

export const availableAggregations = [
    {id: 'day', title: 'D'},
    {id: 'week', title: 'W'},
    {id: 'month', title: 'M'},
    {id: 'quarter', title: 'Q'},
    {id: 'year', title: 'Y'}
]

class FilterStorage {
    getAggregateBy () {
        return Vue.$localStorage.get(aggregateByPrefix, availableAggregations[0].id)
    }
    getDatesFrom () {
        const now = new Date()
        return Vue.$localStorage.get(datesFromPrefix, new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).toISOString().substr(0, 10))
    }
    getDatesTo () {
        return Vue.$localStorage.get(datesToPrefix, new Date().toISOString().substr(0, 10))
    }
    getSourceId () {
        return Vue.$localStorage.get(sourceIdPrefix)
    }
    setAggregateBy (newVal) {
        Vue.$localStorage.set(aggregateByPrefix, newVal)
    }
    setDatesFrom (newVal) {
        Vue.$localStorage.set(datesFromPrefix, newVal)
    }
    setDatesTo (newVal) {
        Vue.$localStorage.set(datesToPrefix, newVal)
    }
    setSourceId (newVal) {
        Vue.$localStorage.set(sourceIdPrefix, newVal)
    }
}

export const filterStorageService = new FilterStorage()