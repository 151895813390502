<template>
  <v-navigation-drawer
      app
      clipped
      permanent
      v-if="isAuthorized"
      v-model="drawer"
      :mini-variant="mini"
  >

    <template v-slot:prepend>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img v-bind:src="avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ fullName }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </template>

    <v-list dense>
      <v-list-item
          color="primary"
          v-for="item in items"
          :key="item.title"
          :to="item.href"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          color="primary"
          v-for="source in sources"
          :key="source.id"
          :to="{name: 'support', params: {id: source.id}}"
      >
        <v-badge
            class="badge"
            bordered
            left
            top
            offset-x="27"
            offset-y="17"
            :content="newSources[source.id]"
            :value="!!newSources[source.id]"
        >
          <v-list-item-avatar
              v-if="source.preview"
              class="preview ml-0 mr-9"
              size="20"
          >
            <v-img :src="source.preview"></v-img>
          </v-list-item-avatar>

          <v-list-item-icon
              v-else
              class="all-icon ml-0 mr-9"
          >
            <v-icon
                size="20"
            >
              mdi-account-group
            </v-icon>
          </v-list-item-icon>
        </v-badge>

        <v-list-item-title v-text="source.title"></v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list-item @click.stop="mini = !mini">
        <v-list-item-icon>
          <v-icon>{{mini ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item href="/api/auth/logout?redirectUri=/">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {getters as countersGetters} from '@/stores/counters'
import {getters as sourceGetters} from '@/stores/sources';
import {getters as userGetters} from '@/stores/user';

export default {
  name: 'Menu',
  computed: {
    ...countersGetters,
    ...sourceGetters,
    ...userGetters
  },
  data () {
    return {
      drawer: false,
      items: [
        { title: 'Дэшборды', icon: 'mdi-widgets', href: '/dashboards' }
      ],
      mini: true
    }
  }
}
</script>

<style>
.all-icon {
  height: 20px!important;
  min-width: 20px!important;
  width: 20px!important;
}
.badge .v-badge__badge {
  font-size: 10px!important;
  height: 15px!important;
  min-width: 15px!important;
  padding: 3px 2px!important;
}
</style>

