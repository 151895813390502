<template>
  <div class="wrapper ml-1 mr-1">
    <v-btn
        v-if="editable"
        icon
        x-small
        class="delete"
        @click="$emit('delete', item)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div
        class="audio"
        v-if="item.type === types.audio.id"
    >
      <audio
          controls
      >
        <source
            type="audio/mpeg"
            :src="item.audio.url"
        >
        Браузер не поддерживает аудиозаписи
      </audio>
      <div
          class="attachment-title mt-1"
      >
        {{item.audio.artist}} - {{item.audio.title}}
      </div>
    </div>

    <div
        class="doc"
        v-if="item.type === types.doc.id"
    >
      <a
          download
          target='_blank'
          :href="item.doc.url"
      >
        <v-icon class="icon">
          mdi-file-document
        </v-icon>

        <div
            class="attachment-title mt-1"
        >
          {{item.doc.title}}
        </div>
      </a>
    </div>

    <div
        class="image"
        @click="$emit('image-click', item.base64 || item.photo.fullImage)"
        v-if="item.type === types.photo.id"
    >
      <img
          :src="item.base64 || item.photo.preview"
      />
    </div>

    <div
        class="video"
        v-if="item.type === types.video.id"
    >
      <video
          width="190"
          controls
      >
        <source
            :src="item.video.url"
              type="video/mp4"
        >
        Браузер не поддерживает видео
      </video>
    </div>
  </div>
</template>

<script>
import {attachmentTypes} from "@/constants/constants";

export default {
  name: 'MessageAttachmentItem',
  data () {
    return {
      types: attachmentTypes
    }
  },
  props: {
    editable: {
      default: false,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.delete {
  position: absolute;
  top: -10px;
  right: -10px;
}
.attachment-title {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.audio {
  width: 191px;
  audio {
    width: 100%;
  }
}
.doc {
  a {
    text-decoration: none;
  }
  .icon {
    font-size: 38px;
  }
  .attachment-title {
    text-decoration: underline;
  }
}
.image {
  cursor: pointer;
  img {
    max-height: 150px;
    max-width: 300px;
  }
}
</style>