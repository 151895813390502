<template>
  <div>
    <div>
      <v-row>
        <v-col
            md="4"
            sm="12"
            xs="12"
        >
          <Search
              text="Введите имя пользователя"
              @query-changed="search"
          ></Search>
        </v-col>
        <template v-if="!$vuetify.breakpoint.mobile">
          <v-col
              md="2"
              sm="3"
              xs="6"
          >
            <v-menu
                v-model="dateFrom"
                left
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    v-model="filter.dateFrom"
                    label="Дата от"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="askData"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="filter.dateFrom"
                  locale="ru-ru"
                  @input="pickerValueSelected('dateFrom')"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
              md="2"
              sm="3"
              xs="6"
          >
            <v-menu
                ref="menu"
                v-model="timeFrom"
                :close-on-content-click="false"
                left
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    v-model="filter.timeFrom"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="askData"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="timeFrom"
                  v-model="filter.timeFrom"
                  format="24hr"
                  full-width
                  :allowed-minutes="allowedTimeStep"
                  @click:minute="pickerValueSelected('timeFrom')"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col
              md="2"
              sm="3"
              xs="6"
          >
            <v-menu
                v-model="dateTo"
                left
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    v-model="filter.dateTo"
                    label="до"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="askData"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="filter.dateTo"
                  locale="ru-ru"
                  @input="pickerValueSelected('dateTo')"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
              md="2"
              sm="3"
              xs="6"
          >
            <v-menu
                ref="menu"
                v-model="timeTo"
                :close-on-content-click="false"
                left
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    v-model="filter.timeTo"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="askData"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="timeTo"
                  v-model="filter.timeTo"
                  format="24hr"
                  full-width
                  :allowed-minutes="allowedTimeStep"
                  @click:minute="pickerValueSelected('timeTo')"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </template>
      </v-row>

      <v-row no-gutters>
        <v-col cols="2" v-if="!$vuetify.breakpoint.mobile">
          <Facets></Facets>
        </v-col>

        <v-col sm="10" xs="12">
          <div v-if="data">
            <v-list
                dense
                three-line
            >
              <template v-for="rq in data">
                <SupportRequestPreview
                    :new-requests="newRequests"
                    :key="rq.id"
                    :profiles="profiles"
                    :request="rq"
                    :source-id="sourceId"
                ></SupportRequestPreview>
              </template>
            </v-list>

            <div class="text-center">
              <v-pagination
                  v-if="hasPages"
                  v-model="page"
                  v-on:input="askData"
                  :length="pages"
              ></v-pagination>
            </div>
          </div>
          <div v-if="!resolved">
            <v-skeleton-loader
                type="list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                type="list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                type="list-item-avatar-two-line"
            ></v-skeleton-loader>
          </div>
          <Info
              v-if="error"
              type="error"
              :message="error"
          ></Info>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {getters as countersGetters} from '@/stores/counters';
import {actions as facetActions, getters as facetGetters} from '@/stores/facets';
import {actions as sourceActions} from '@/stores/sources';
import {actions, getters as supportGetters} from '@/stores/support';
import {getters as tagGetters} from '@/stores/tags';

import Search from '@/components/Search';
import SupportRequestPreview from '@/components/SupportRequestPreview';
import Facets from '@/components/Facets';
import {wsEvents, wsService} from '@/services/ws';
import Info from "@/components/Info";
export default {
  name: 'Support',
  components: {Info, Facets, SupportRequestPreview, Search},
  computed: {
    ...countersGetters,
    ...supportGetters.requests,
    selectedSize: facetGetters.selectedSize
  },
  mounted: function () {
    wsService.bus.$on(wsEvents.supportRequestsUpdate, data => {
      if (this.filter.sourceIds.includes(data.sourceId)) {
        actions.requests.silentAsk(this.page, this.filter, facetGetters.selected())
      }
    })
    this.selectSource(this.$route.params.id)
  },
  destroyed() {
    wsService.bus.$off(wsEvents.supportRequestsUpdate)
  },
  data () {
    return {
      dateFrom: false,
      dateTo: false,
      timeFrom: false,
      timeTo: false,
      filter: {
        q: '',
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        sourceIds: []
      },
      modelConfig: {
        type: 'number',
      },
      page: 1,
      sourceId: ''
    }
  },
  methods: {
    askData (page) {
      this.page = page || 1
      actions.requests.askData(this.page, this.filter, facetGetters.selected())
          .then(data => {
            facetActions.fillData(
                data.facets, {
                  executors: data.profiles,
                  tags: tagGetters.map()
                 }, this.selectedSize ? facetGetters.selected() : {
              executors: [],
              tags: []
            })
          }
        )
    },
    allowedTimeStep: m => m % 5 === 0,
    dateFilterChanged () {
      this.askData()
    },
    initialize (sourceIds) {
      this.filter.sourceIds = sourceIds
      this.initialized = true
    },
    pickerValueSelected (pickerName) {
      this[pickerName] = false
      this.askData()
    },
    search (query) {
      this.filter.q = query
      if (!this.filter.sourceIds.length) {
        return
      }
      this.askData()
    },
    selectSource (id) {
      this.sourceId = id
      this.filter.sourceIds = sourceActions.getIdsById(id)
      facetActions.clearData()
      this.askData()
    }
  },
  watch: {
    selectedSize: function () {
      this.askData()
    },
    '$route.params.id': function (id) {
      this.selectSource(id)
    }
  }
}
</script>

<style scoped>

</style>