<template>
  <v-dialog
      max-width="1200px"
      persistent
      scrollable
      v-model="opened"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Список подписок</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>
                  Тип
                </th>
                <th>
                  Тип оплаты
                </th>
                <th>
                  Статус
                </th>
                <th>
                  Big Boss
                </th>
                <th>
                  Создана
                </th>
                <th>
                  Стартует
                </th>
                <th>
                  Протухает
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in subscriptions"
                  :key="item.name"
                  :id="item.id"
              >
                <td>{{ getTypeTitle(item) }}</td>
                <td>{{ item.paymentType }}</td>
                <td>{{ item.status }}</td>
                <td>
                  <v-icon
                      v-if="item.isBigBoss"
                  >
                    mdi-check-box-outline
                  </v-icon>
                  <v-icon
                      v-else
                  >
                    mdi-checkbox-blank-outline
                  </v-icon>
                </td>
                <td>{{ item.tsCreate | date }}</td>
                <td>{{ item.tsStart | date }}</td>
                <td
                    class="lighten-4"
                    :class="{'green--text': item.active, 'red--text': !item.active}"
                >{{ item.tsExpire | date }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="darken-1"
            text
            @click="$emit('close')"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {subscriptionTypes} from '@/constants/constants';

export default {
  name: 'DialogCustomerSubscriptions',
  methods: {
    getTypeTitle (item) {
      return subscriptionTypes[item.type] && subscriptionTypes[item.type].title
    }
  },
  props: {
    opened: {
      required: true,
      type: Boolean
    },
    subscriptions: {
      default: () => [],
      type: Array
    }
  },
}
</script>

<style scoped>

</style>