<template>
  <v-dialog
      max-width="600px"
      persistent
      v-model="opened"
  >
    <v-card>
      <v-card-title class="headline">
        Удаление дэшборда
      </v-card-title>

      <v-card-text>
        Вы уверены, что хотите удалить дэшборд "{{dashboard.title}}"?
      </v-card-text>

      <div v-if="error">
        <v-alert
            color="red lighten-4"
        >
          {{ error }}
        </v-alert>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="darken-1"
            text
            @click="close()"
        >
          Отмена
        </v-btn>
        <v-btn
            color="red lighting-2"
            text
            @click="confirmDeleteDashboard()"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogDashboardDelete',
  data() {
    return {
      error: null
    }
  },
  props: {
    opened: {
      required: true
    },
    dashboard: {
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async confirmDeleteDashboard() {
      this.updateProcess = true
      try {
        await this.$dashboard.delete(this.dashboard.id)
        this.$emit('submit')
      } catch (err) {
        this.error = err
      }
    }
  }
}
</script>

<style scoped>

</style>