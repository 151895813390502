import StoreItem from '@/classes/StoreItem';

export default class StoreList extends StoreItem {
    hasPages = false
    limit
    pages = 0
    size = 0
    lastPage
    lastFilter
    lastArgs
    processAppend = false

    constructor (limit, additionalFields) {
        super(additionalFields)
        this.limit = limit
        this.getters.hasPages = () => this.hasPages
        this.getters.pages = () => this.pages
        this.getters.size = () => this.size
    }

    appendFromRs (rs, merge) {
        this._appendFields(rs, merge)
        this._appendAdditionalFields(rs)
        this.setProcessAppend(false)
    }

    fillError (err) {
        this.error = err && err.error ? err.error.message : err
        this.setResolve(true)
        this.setProcessAppend(false)
    }

    getRqData (page, filter, args) {
        this.lastPage = page
        this.lastFilter = filter
        this.lastArgs = args
        return Object.assign({
            limit: this.limit,
            offset: (page - 1) * this.limit,
            filter
        }, args)
    }

    getRqDataAllPortion () {
        return Object.assign({
            limit: (Math.floor(this.data.length / this.limit) + (this.data.length % this.limit ? this.limit : 0)) * this.limit,
            offset: 0,
            filter: this.lastFilter
        }, this.lastArgs)
    }

    getRqDataNextPortion () {
        return Object.assign({
            limit: this.limit,
            offset: this.data.length,
            filter: this.lastFilter
        }, this.lastArgs)
    }

    setProcessAppend (val) {
        this.processAppend = val
    }

    _clearFields () {
        super._clearFields()
        this.hasPages = false
        this.pages = 0
        this.size = 0
    }

    _fillFields (rs, items) {
        if (!items) {
            this.data = rs.items
        } else {
            this.data = items
        }
        this.pages = Math.floor(rs.size / this.limit)
        this.profiles = rs.profiles
        this.size = rs.size
        this.hasPages = rs.size > this.limit
    }

    _appendFields (rs, merge) {
        if (merge) {
            this.data = rs.items
        } else {
            this.data = rs.items.concat(this.data)
        }
        this.pages = Math.floor(rs.size / this.limit)
        this.profiles = Object.assign({}, this.profiles, rs.profiles)
        this.size = rs.size
        this.hasPages = rs.size > this.data.length
    }

    _appendAdditionalFields(from) {
        for (const field of this.additionalFields) {
            this[field] = Object.assign({}, this[field], from[field])
        }
    }
}