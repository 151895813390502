import Vue from 'vue'

export const sectionList = {
    executors: {
        id: 'executors',
        title: 'Исполнители'
    },
    tags: {
        id: 'tags',
        title: 'Тэги'
    }
}

export const facets = new Vue.observable({
    data: {},
    map: {},
    resolved: false,
    selected: {},
    selectedSize: 0
})

export const actions = {
    clearData () {
        facets.data = {}
        facets.map = {}
        facets.selected = {}
        facets.selectedSize = 0
        facets.resolved = false
    },
    fillData (data, map, selected) {
        facets.data = data
        facets.map = map
        facets.selected = selected
        facets.resolved = true
    },
    getSectionSelected (sectionId) {
        return facets.selected[sectionId]
    },
    getSectionTitle (sectionId) {
        return sectionList[sectionId].title
    },
    getTitle (sectionId, itemId) {
        let fieldName;
        switch (sectionId) {
            case sectionList.executors.id:
                fieldName = 'fullName'
                break;
            default:
                fieldName = 'title'
        }
        return facets.map[sectionId][itemId] ? facets.map[sectionId][itemId][fieldName] : itemId
    },
    hasSelectedItem (sectionId, itemId) {
        return facets.selected[sectionId].indexOf(itemId) > -1
    },
    selectClear () {
        const newSelected = {}
        for (const key in facets.data) {
            newSelected[key] = []
        }
        facets.selected = newSelected
        facets.selectedSize = 0
    },
    selectToggle (sectionId, itemId) {
        const section = facets.selected[sectionId]
        const idx = section.indexOf(itemId)
        if (idx < 0) {
            section.push(itemId)
            facets.selectedSize++
        } else {
            section.splice(idx, 1)
            facets.selectedSize--
        }
        facets.selected = Object.assign({}, facets.selected)
    }
}

export const getters = {
    data: () => facets.data,
    map: () => facets.map,
    resolved: () => facets.resolved,
    selected: () => facets.selected,
    selectedSize: () => facets.selectedSize
}