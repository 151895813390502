<template>
  <v-footer
      app
      class="panel resizable"
      color="white"
      height="auto"
      inset
  >
    <v-row
        class="d-flex"
        dense
        no-gutters
    >
      <v-col
          class="align-self-end"
          cols="auto"
      >
        <v-menu
            offset-y
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-paperclip</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-for="item of availableAttachmentTypes"
                :key="item.id"
                @click="addAttachment(item.id)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
          class="textarea-wrapper px-2"
      >
        <v-row
            v-if="content.id"
            class="edit-title"
            dense
        >
          <v-col>
            Редактирование сообщения
          </v-col>
          <v-col
              cols="auto"
          >
            <v-btn
                icon
                x-small
                :ripple="false"
                @click="$emit('cancel')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-textarea
            autofocus
            dense
            outlined
            hide-details
            placeholder="Напишите сообщение..."
            ref="editor"
            rows="1"
            v-model="content.text"
            @click:append="pickerToggle"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="process"
            @keyup.up="startEditMode"
            :auto-grow="true"
            :no-resize="true"
        ></v-textarea>

        <v-btn
            class="btn-picker-toggle"
            icon
            :color="picker ? 'primary' : ''"
            @click="pickerToggle"
        >
          <v-icon>mdi-emoticon-poop</v-icon>
        </v-btn>

        <VEmojiPicker
            v-if="picker"
            class="picker"
            :showSearch="false"
            v-on:select="addEmoji"
        ></VEmojiPicker>
      </v-col>

      <v-col
          class="align-self-end"
          cols="auto"
      >
        <v-btn
            color="primary"
            icon
            :disabled="disabled"
            @click="process"
        >
          <v-icon v-if="!content.id">mdi-send</v-icon>
          <v-icon v-if="editableHasContent()">mdi-check</v-icon>
          <v-icon v-if="editableNoContent()">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <FileReader
        ref="reader"
        @load="attachmentAdded"
    ></FileReader>
    <v-row
        v-if="content.attachments && content.attachments.length"
        class="d-flex mt-3"
        dense
        no-gutters
    >
      <v-col
          cols="12"
      >
        <MessageAttachmentList
            class="attachments"
            editable
            :attachments="content.attachments"
            @delete="attachmentDelete"
        ></MessageAttachmentList>
      </v-col>
    </v-row>
    <v-row
      v-if="content.forwardedMessages && content.forwardedMessages.length"
      class="d-flex mt-3"
      dense
      no-gutters
    >
      <v-col>
        <MessageForwardedList
            class="forwarded-messages"
            :forwardedMessages="content.forwardedMessages"
        >
          <template v-slot:actions>
            <v-btn
                icon
                x-small
                :ripple="false"
                @click="$emit('clearForwardedMessages')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </MessageForwardedList>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import {attachmentTypes} from "@/constants/constants";
import MessageAttachmentList from "@/components/MessageAttachmentList";
import FileReader from "@/components/FileReader";
import MessageForwardedList from "@/components/MessageForwardedList";
let area
export default {
  name: 'MessageEditor',
  components: {
    MessageForwardedList,
    FileReader,
    MessageAttachmentList,
    VEmojiPicker
  },
  mounted () {
    area = this.$refs.editor.$el.querySelector('textarea')
  },
  data () {
    return {
      availableAttachmentTypes: [
        attachmentTypes.doc,
        attachmentTypes.photo
      ],
      picker: false
    }
  },
  methods: {
    addAttachment (type) {
      this.$refs.reader.openSelectDialog(type)
    },
    attachmentAdded (type, file, base64) {
      const a = {
        type: type,
        base64: base64
      }
      switch (type) {
        case attachmentTypes.doc.id:
          a.doc = {
            title: file.name
          }
          break;
      }
      this.$emit('attachmentAdd', a)
    },
    attachmentDelete (idx) {
      this.$emit('attachmentDelete', idx)
    },
    addEmoji (event) {
      this.picker = false
      this.content.text = this.content.text.slice(0, area.selectionStart)
          + event.data + this.content.text.slice(area.selectionEnd)
    },
    editableHasContent() {
      return this.content.id && (
          this.content.text.length
          || this.content.attachments && this.content.attachments.length
          || this.content.forwardedMessages && this.content.forwardedMessages.length)
    },
    editableNoContent() {
      return this.content.id && !this.editableHasContent()
    },
    pickerToggle () {
      this.picker = !this.picker
    },
    deleteMsg () {
      this.$emit('delete', this.content)
    },
    process () {
      if (this.editableHasContent()) {
        this.update()
      } else if (this.editableNoContent()) {
        this.deleteMsg()
      } else {
        this.send()
      }
    },
    send () {
      if (!this.content.text && !this.content.attachments.length && !this.content.forwardedMessages.length) {
        return
      }
      this.$emit('send', this.content)
    },
    startEditMode () {
      if (this.content.id || this.content.text.length) {
        return
      }
      this.$emit('editMode')
    },
    update () {
      this.$emit('update', this.content)
    }
  },
  props: {
    content: {
      type: Object
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.panel {
  display: block;
}
.edit-title {
  margin-bottom: 0;
}
.textarea-wrapper {
  position: relative;
}
.btn-picker-toggle {
  position: absolute;
  bottom: 2px;
  right: 10px;
}
.picker {
  position: absolute;
  bottom: 42px;
  right: 8px;
}
.attachments {
  margin: 0 40px;
}
.forwarded-messages {
  margin: 0 40px 0 45px;
}
</style>