<template>
  <div>
    <svg :id="`svg-${this.uid}`" width="100%" height="100%"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'

function formatNumbers (value) {
  if (value) {
    return new Intl.NumberFormat().format(+value)
  }
}


export default {
  name: 'DashboardItemData',
  props: {
    aggregateBy: {
      required: true,
      type: String
    },
    dashboard: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object
    },
    width: {
      default: 500,
      type: Number,
    },
    height: {
      default: 270,
      type: Number,
    }
  },
  data() {
    return {
      uid: this._uid
    };
  },
  mounted () {
    const bisect = mx => {
      const date = x.invert(mx)
      const index = d3.bisector(d => d.date).left(data, date, 1)
      const a = data[index - 1]
      const b = data[index]
      return b && (date - a.date > b.date - date) ? b : a
    }
    const callout = (g, value) => {
      if (!value) return g.style('display', 'none');

      g
          .style('display', null)
          .style('pointer-events', 'none')
          .style('font', '10px sans-serif')

      const path = g.selectAll('path')
          .data([null])
          .join('path')
          .attr('fill', 'white')
          .attr('class', 'arc')
          .attr('stroke', 'black')

      const tooltipData = (value + '').split(/\n/).map((val, idx) => {
        if (!idx) {
          return formatNumbers(val)
        } else {
          return val.trim()
        }
      })

      const text = g.selectAll('text')
          .data([null])
          .join('text')
          .call(text => text
              .selectAll('tspan')
              .data(tooltipData)
              .style('font', '6px roboto')
              .join('tspan')
              .attr('x', 0)
              .attr('y', (d, i) => `${i * 1.1}em`)
              .style('font-weight', (_, i) => i ? null : 'bold')
              .text(d => d))

      const {y, width: w, height: h} = text.node().getBBox();

      text.attr('transform', `translate(${-w / 2},${15 - y})`);
      path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
    }
    const data = this.data.data.map(({date, value}) => ({date: d3.utcParse('%Y-%m-%d')(date), value}))
    const formatDate = date => {
      return date.toLocaleString('en', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'
      })
    }
    const margin = ({top: 20, right: 30, bottom: 30, left: 40})
    const line = d3[this.dashboard.type]()
        .defined(d => !isNaN(d.value))
        .x(d => x(d.date))
        .y(d => y(d.value))

    const x = d3.scaleUtc()
        .domain(d3.extent(data, d => d.date))
        .range([margin.left, this.width - margin.right])

    const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)]).nice()
        .range([this.height - margin.bottom, margin.top])

    const svg = d3.select(`#svg-${this.uid}`)
        .attr('viewBox', [0, 0, this.width, this.height])
        .style('-webkit-tap-highlight-color', 'transparent')
        .style('overflow', 'visible');

    svg.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).tickSize(-this.width + margin.left + margin.right).tickFormat(''))

    svg.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${this.height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSize(-this.height + margin.bottom + margin.top).tickFormat(''))

    const xAxis = g => g
        .style('font', '5px roboto')
        .attr('transform', `translate(0,${this.height - margin.bottom})`)
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%b')))

    const yAxis = g => g
        .style('font', '5px roboto')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select('.tick:last-of-type text').clone()
            .attr('x', 3)
            .attr('text-anchor', 'start')
            .attr('font-weight', 'bold')
            .text(this.data.title))

    svg.append('g')
        .call(xAxis)

    svg.append('g')
        .call(yAxis)

    svg.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', '#1976d2')
        .attr('stroke-width', 1)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', line)

    const tooltip = svg.append('g')

    svg.on('touchmove mousemove', function(event) {
      const {date, value} = bisect(d3.pointer(event, this)[0]);

      tooltip
          .attr('transform', `translate(${x(date)},${y(value)})`)
          .attr('z-index', `9999`)
          .call(callout, `${value}
          ${formatDate(date)}`);
    });

    svg.on('touchend mouseleave', () => tooltip.call(callout, null));

  }
}
</script>

<style lang="scss">
.grid {
  line {
    stroke: lightgrey;
    stroke-width: 0.5;
    stroke-opacity: 0.5;
    shape-rendering: crispEdges;
  }
  path {
    stroke-width: 0;
  }
}
</style>