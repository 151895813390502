<template>
  <div
      :id="request.id"
  >
    <v-list-item
        :to="{name: 'supportRequest', params: {id: sourceId, requestId: request.id}}"
        :class="newRequests.indexOf(request.id) > -1 ? 'blue lighten-5' : ''"
    >
    <v-list-item-avatar class="avatar">
      <v-img
          :src="customer.photo"
      ></v-img>
      <img v-if="adminAuthor" class="admin-author-preview" :src="adminAuthor.photo"/>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{customer.fullName}}
        <v-chip
            v-for="tag of request.tags"
            v-bind:key="tag"
            class="ma-1"
            x-small
            text-color="white"
            :color="map[tag].color"
            @click="toggleTag($event, tag)"
        >
          {{map[tag].title}}
        </v-chip>
      </v-list-item-title>
      <v-list-item-action-text v-if="$vuetify.breakpoint.mobile">
        {{request.lastMessage.date | date}}
      </v-list-item-action-text>

      <v-list-item-subtitle
          v-if="executor"
          class="executor"
      >
        <a @click="toggleExecutor($event, executor.id)">
          Отвечает: {{executor.fullName}}
        </a>
      </v-list-item-subtitle>
      <v-list-item-subtitle
          v-if="request.lastMessage && request.lastMessage.text"
          v-html="request.lastMessage.text"
      ></v-list-item-subtitle>
      <v-list-item-subtitle
          v-else-if="request.lastMessage && request.lastMessage.attachments && request.lastMessage.attachments.length"
      >
        {{request.lastMessage.attachments.length | wordend(['вложение', 'вложения', 'вложений'])}}
      </v-list-item-subtitle>
      <v-list-item-subtitle
          v-else-if="request.lastMessage && request.lastMessage.forwardedMessages && request.lastMessage.forwardedMessages.length"
      >
        1 сообщение
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="request.lastMessage">
      <v-list-item-action-text v-if="!$vuetify.breakpoint.mobile">
        {{request.lastMessage.date | date}}
      </v-list-item-action-text>

      <v-btn
          icon
          color="primary"
          target="_blank"
          :href="request.lastMessage.url"
      >
        <v-icon>mdi-forward</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import {getters} from "@/stores/tags";
import {actions as facetActions, sectionList} from "@/stores/facets";

export default {
  name: 'SupportRequestPreview',
  computed: {
    ...getters
  },
  data () {
    return {
      adminAuthor: this.request.lastMessage ? this.profiles[this.request.lastMessage.adminAuthorId] : null,
      customer: this.profiles[this.request.customerId],
      executor: this.profiles[this.request.executorId]
    }
  },
  methods: {
    toggleTag: ($event, id) => {
      $event.preventDefault()
      facetActions.selectToggle(sectionList.tags.id, id)
    },
    toggleExecutor: ($event, id) => {
      $event.preventDefault()
      facetActions.selectToggle(sectionList.executors.id, id)
    }
  },
  props: {
    profiles: {
      required: true,
      type: Object
    },
    newRequests: {
      default: () => [],
      type: Array
    },
    request: {
      required: true,
      type: Object
    },
    sourceId: {
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/scss/shared.scss';
  .avatar {
    @include avatar-overflow-visible();
  }
  .admin-author-preview {
    @include author-preview();
  }
  .executor {
    font-weight: 500;
    text-decoration: underline;
  }
</style>