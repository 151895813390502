<template>
  <input
      id="file-reader"
      multiple
      ref="input"
      type="file"
      :accept="accept"
      @change="loadTextFromFile"
  />
</template>

<script>
import {attachmentTypes} from "@/constants/constants";
let lastType

export default {
  data () {
    return {
      accept: ''
    }
  },
  name: 'FileReader',
  methods: {
    openSelectDialog (type) {
      lastType = type
      switch (type) {
        case attachmentTypes.photo.id:
          this.accept = 'image/*'
          break;
        default:
          this.accept = '.zip'
      }
      setTimeout(() => this.$refs.input.click(), 0)
    },
    loadTextFromFile (ev) {
      for (const file of ev.target.files) {
        const reader = new FileReader();

        reader.onload = e => {
          this.$emit('load', lastType, file, e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
  }
}
</script>

<style scoped>
#file-reader {
  display: none;
}
</style>