var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","xs":"12"}},[_c('Search',{attrs:{"text":"Введите имя пользователя"},on:{"query-changed":_vm.search}})],1),(!_vm.$vuetify.breakpoint.mobile)?[_c('v-col',{attrs:{"md":"2","sm":"3","xs":"6"}},[_c('v-menu',{attrs:{"left":"","transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Дата от","readonly":""},on:{"input":_vm.askData},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on))]}}],null,false,4073189794),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-date-picker',{attrs:{"locale":"ru-ru"},on:{"input":function($event){return _vm.pickerValueSelected('dateFrom')}},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1),_c('v-col',{attrs:{"md":"2","sm":"3","xs":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},on:{"input":_vm.askData},model:{value:(_vm.filter.timeFrom),callback:function ($$v) {_vm.$set(_vm.filter, "timeFrom", $$v)},expression:"filter.timeFrom"}},'v-text-field',attrs,false),on))]}}],null,false,3397588642),model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}},[(_vm.timeFrom)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":"","allowed-minutes":_vm.allowedTimeStep},on:{"click:minute":function($event){return _vm.pickerValueSelected('timeFrom')}},model:{value:(_vm.filter.timeFrom),callback:function ($$v) {_vm.$set(_vm.filter, "timeFrom", $$v)},expression:"filter.timeFrom"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"2","sm":"3","xs":"6"}},[_c('v-menu',{attrs:{"left":"","transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"до","readonly":""},on:{"input":_vm.askData},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on))]}}],null,false,438973647),model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-date-picker',{attrs:{"locale":"ru-ru"},on:{"input":function($event){return _vm.pickerValueSelected('dateTo')}},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1),_c('v-col',{attrs:{"md":"2","sm":"3","xs":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},on:{"input":_vm.askData},model:{value:(_vm.filter.timeTo),callback:function ($$v) {_vm.$set(_vm.filter, "timeTo", $$v)},expression:"filter.timeTo"}},'v-text-field',attrs,false),on))]}}],null,false,2277841167),model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}},[(_vm.timeTo)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":"","allowed-minutes":_vm.allowedTimeStep},on:{"click:minute":function($event){return _vm.pickerValueSelected('timeTo')}},model:{value:(_vm.filter.timeTo),callback:function ($$v) {_vm.$set(_vm.filter, "timeTo", $$v)},expression:"filter.timeTo"}}):_vm._e()],1)],1)]:_vm._e()],2),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"cols":"2"}},[_c('Facets')],1):_vm._e(),_c('v-col',{attrs:{"sm":"10","xs":"12"}},[(_vm.data)?_c('div',[_c('v-list',{attrs:{"dense":"","three-line":""}},[_vm._l((_vm.data),function(rq){return [_c('SupportRequestPreview',{key:rq.id,attrs:{"new-requests":_vm.newRequests,"profiles":_vm.profiles,"request":rq,"source-id":_vm.sourceId}})]})],2),_c('div',{staticClass:"text-center"},[(_vm.hasPages)?_c('v-pagination',{attrs:{"length":_vm.pages},on:{"input":_vm.askData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1):_vm._e(),(!_vm.resolved)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1):_vm._e(),(_vm.error)?_c('Info',{attrs:{"type":"error","message":_vm.error}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }