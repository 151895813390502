<template>
  <div v-if="values.length" class="mb-4">
      <dl>
        <dt class="text-caption pb-2">
          {{ getSectionTitle(id) }}
        </dt>
        <dd v-for="item in values" v-bind:key="item.key">
          <v-checkbox
              class="mt-0 mb-1"
              dense
              hide-details
              :ripple="false"
              v-model="selectedItems"
              :value="item.key"
              @click="selectToggle(id, item.key)"
          >
            <template v-slot:label>
              <div class="text-caption">
                {{ getTitle(id, item.key) }}
                <sup>
                  {{ item.value }}
                </sup>
              </div>
            </template>
          </v-checkbox>
        </dd>
      </dl>
    </div>
</template>

<script>
import {actions, getters} from "@/stores/facets";

export default {
  name: 'FacetSection',
  computed: {
    ...getters
  },
  data () {
    return {
      selectedItems: []
    }
  },
  methods: {
    ...actions
  },
  props: {
    id: {
      required: true,
      type: String
    },
    values: {
      required: true,
      type: Array
    },
  },
  watch: {
    selected: function () {
      const selectedSection = actions.getSectionSelected(this.id)
      this.selectedItems = JSON.parse(JSON.stringify(selectedSection))
    }
  }
}
</script>

<style scoped>

</style>