import axios from 'axios'
import Vue from 'vue'
import { apiPrefix } from '@/constants/constants';

export const tags = new Vue.observable({
    colors: [
        '#F44336',
        '#B71C1C',
        '#E91E63',
        '#880E4F',
        '#9C27B0',
        '#4A148C',
        '#673AB7',
        '#311B92',
        '#3F51B5',
        '#1A237E',
        '#2196F3',
        '#0D47A1',
        '#03A9F4',
        '#01579B',
        '#00BCD4',
        '#006064',
        '#009688',
        '#004D40',
        '#4CAF50',
        '#1B5E20',
        '#8BC34A',
        '#33691E',
        '#CDDC39',
        '#827717',
        '#FFEB3B',
        '#F57F17',
        '#FFC107',
        '#FF6F00',
        '#FF9800',
        '#E65100',
        '#FF5722',
        '#BF360C',
        '#795548',
        '#3E2723',
        '#607D8B',
        '#263238',
        '#9E9E9E',
        '#212121'
    ],
    list: [],
    map: {},
    size: 0,
    resolved: false
})

function _fillTags (data) {
    tags.list = data.items
    for (const tag of tags.list) {
        tags.map[tag.id] = tag
    }
    tags.size = data.size
}

export const actions = {
    askList () {
        return axios.get(`${apiPrefix}/tags`)
            .then(rs => _fillTags(rs))
            .finally(() => {
                tags.resolved = true
            })
    },
    update (newList) {
        return axios.post(`${apiPrefix}/tags/update`, {items: newList})
            .then(rs => _fillTags(rs))
    }
}

export const getters = {
    colors: () => tags.colors,
    list: () => tags.list,
    map: () => tags.map,
    size: () => tags.size,
    resolved: () => tags.resolved
}