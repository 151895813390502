import SockJS from 'sockjs-client'
import Vue from 'vue'
import {actions} from '@/stores/counters'

const pingInterval = 5000
const reconnectionInterval = 500

const states = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
};

function getTimedMessage(msg){
    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' }
    const now  = new Date()
    return `${now.toLocaleDateString('ru-RU', options)}.${now.getMilliseconds()}: ${msg}`
}

function makeUUID(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (a, b) => {
        return b = Math.random() * 16, (a == 'y' ? b & 3 | 8 : b | 0).toString(16);
    });
}

class WS {
    bus = new Vue()
    _pingTimerID
    _state

    connect () {
        const wsAddress = ~window.location.host.indexOf('localhost') ? 'https://органайзер.сп-помощник.рф/ws' : '/ws'
        this.socket = new SockJS(wsAddress)

        this.socket.onopen = () => {
            console.log(getTimedMessage('WS connection opened'))

            this._startPing()
            this._state = states.OPEN

            this.sendMessage({
                type: 'register',
                address: wsEvents.supportUpdates
            })
            this._onUpdateCounters()
        };

        this.socket.onclose = () => {
            console.log(getTimedMessage('WS connection closed'))
            this._stopPing()
            this._state = states.CLOSED
            this._offUpdateCounters()
            this._tryToReconnect()
        };

        this.socket.onmessage = e => {
            let msgData = JSON.parse(e.data)
            if (msgData.body) {
                console.log(getTimedMessage(`message "${msgData.body.type}" from server received`), msgData)
                this.bus.$emit(msgData.body.type, msgData.body.data)
            } else {
                console.log(getTimedMessage('raw msg from server received'), msgData)
            }

            // if (msgData.type === MSG_TYPES.RECORD){
            //     _observable.emit(EVENTS.MESSAGE, msgData.body)
            // } else if (msgData.type === MSG_TYPES.ERROR){
            //     _observable.emit(EVENTS.ERROR, msgData)
            // }
        }

    }

    sendMessage (msg) {
        console.log(getTimedMessage(`send msg "${msg.address}" to server`), msg);
        // are we ready?
        if (this._state !== states.OPEN) {
            throw new Error('INVALID_STATE_ERR');
        }

        let envelope = {
            type: msg.type || 'send',
            address: msg.address,
            replyAddress: makeUUID(),
            body: msg.body
        };

        this.socket.send(JSON.stringify(envelope));
    }

    _sendPing () {
        this.socket.send(JSON.stringify({type: 'ping'}))
    }

    _startPing () {
        this._sendPing()
        this._pingTimerID = setInterval(this._sendPing.bind(this), pingInterval)
    }
    _stopPing () {
        this._pingTimerID && clearInterval(this._pingTimerID)
    }
    _tryToReconnect () {
        setTimeout(() => {
            console.log(getTimedMessage('try to reconnect'))
            this.connect()
        }, reconnectionInterval)
    }
    _onUpdateCounters () {
        this.bus.$on(wsEvents.supportRequestsCountersNew, data => {
            actions.fill(data)
        })
    }
    _offUpdateCounters () {
        this.bus.$off(wsEvents.supportRequestsCountersNew)
    }
}

export const wsService = new WS()

export const wsEvents = {
    supportUpdates: 'client.support.updates',
    supportRequestsCountersNew: 'client.support.requests.counters.new',
    supportRequestsUpdate: 'client.support.requests.update'
}