<template>
  <v-dialog
      v-model="opened"
      max-width="600px"
      persistent
      scrollable
  >
    <v-form
        @submit="submit"
        ref="form"
        v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ dashboard.id ? 'Редактирование информации' : 'Добавить дэшборд'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    label="Введите название"
                    v-model="dashboard.title"
                    :counter="50"
                    :rules="titleRules"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-select
                    :items="dashboardTypes"
                    item-text="title"
                    item-value="id"
                    label="Выберите тип"
                    v-model="dashboard.type"
                    required
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    label="Введите endpoint"
                    v-model="dashboard.endpoint"
                    :rules="endpointRules"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-checkbox
                    v-model="dashboard.allowDateAggregation"
                    :ripple="false"
                    label="Фильтр по датам поддерживает агрегацию"
                ></v-checkbox>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-select
                    :items="userRoles"
                    item-text="title"
                    item-value="id"
                    label="Доступно для ролей"
                    v-model="dashboard.visibilityRole"
                    required
                ></v-select>
              </v-col>
            </v-row>
            <div v-if="error">
              <v-alert
                  color="red lighten-4"
              >
                {{ error }}
              </v-alert>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="close()"
          >
            Отмена
          </v-btn>
          <v-btn
              color="success"
              text
              type="submit"
              :loading="updateProcess"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {roles} from '@/stores/user';
import {types} from '@/services/dashboard';

export default {
  name: 'DialogDashboardEditor',
  data () {
    return {
      dashboardTypes: types,
      userRoles: roles,
      titleErrors: [],
      titleRules: [
        v => !!v || 'Введите название',
        v => v.length <= 50 || 'Не более 50 символов'
      ],
      endpointRules: [
        v => !!v || 'Введите endpoint'
      ],
      valid: false,
      error: '',
      updateProcess: false
    }
  },
  props: {
    opened: {
      required: true
    },
    dashboard: {
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async submit () {
      this.error = null
      this.$refs.form.validate()
      if (this.valid) {
        this.updateProcess = true
        try {
          const res = await (this.dashboard.id ? this.$dashboard.update(this.dashboard) : this.$dashboard.create(this.dashboard))
          this.$emit('submit', res)
        } catch (err) {
          if (err.validation) {
            this.errors = err.validation
          } else {
            this.updateError = err.error || err
          }
        }
        this.updateProcess = false
      }
    }
  }
}
</script>

<style scoped>

</style>