<template>
  <v-menu
      v-model="menu"
      bottom
      right
      transition="scale-transition"
      origin="top left"
  >
    <template v-slot:activator="{ on }">
      <v-chip
          class="ma-2"
          small
          text-color="white"
          v-on="on"
          :color="rateLocalized.color"
          :disabled="disabled"
      >
        {{ rateLocalized.id }}
        <v-icon
            right
            v-if="!disabled"
        >
          mdi-chevron-down
        </v-icon>
        <v-progress-circular
            v-else
            class="ml-2"
            color="white"
            indeterminate
            size="15"
            width="2"
        ></v-progress-circular>
      </v-chip>
    </template>
    <v-list>
      <v-subheader>Выберите сложность заявки</v-subheader>
      <v-list-item
          v-for="r in rates"
          :key="r.id"
          @click="$emit('change', r.id)"
      >
        <v-list-item-title v-text="r.id"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const rates = [
  {id: 0, color: 'green'},
  {id: 1, color: 'green lighten-2'},
  {id: 2, color: 'green lighten-3'},
  {id: 3, color: 'red lighten-3'},
  {id: 4, color: 'red lighten-2'},
  {id: 5, color: 'red'}
]
export default {
  name: 'SupportRequestDifficultRate',
  computed: {
    rateLocalized: function () { return rates.find(s => s.id === this.difficultRate) }
  },
  data () {
    return {
      menu: false,
      rates: rates
    }
  },
  props: {
    disabled: {
      required: true,
      type: Boolean
    },
    difficultRate: {
      default: 0,
      type: Number
    }
  }
}
</script>

<style scoped>

</style>