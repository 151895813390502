import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardList from '@/components/DashboardList'
import DashboardItem from '@/components/DashboardItem'
import Support from '@/components/Support'
import SupportRequest from '@/components/SupportRequest'
import SupportWrapper from "@/components/SupportWrapper";

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboards',
    name: 'dashboards',
    component: DashboardList
  },
  {
    path: '/dashboards/:id',
    name: 'dashboardsItem',
    component: DashboardItem
  },
  {
    path: '/support/:id',
    component: SupportWrapper,
    children: [
      {path: '', name: 'support', component: Support},
      {path: 'request/:requestId', name: 'supportRequest', component: SupportRequest},
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
