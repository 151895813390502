<template>
  <div>
    <v-row v-if="dashboard && !$vuetify.breakpoint.mobile">
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-menu
            v-model="fromOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                clearable
                v-model="from"
                label="Показать данные от"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="fromSelected"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="from"
              locale="ru-ru"
              @input="fromSelected"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-menu
            v-model="toOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                clearable
                v-model="to"
                label="до"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="toSelected"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="to"
              locale="ru-ru"
              @input="toSelected"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
          v-if="dashboard.allowDateAggregation"
          cols="12"
          sm="12"
          md="4"
      >
        <v-btn-toggle
            borderless
            mandatory
            v-model="aggregateBy"
            @change="aggregateBySelected"
        >
          <v-btn
              v-for="aggregation in availableAggregations"
              v-bind:key="aggregation.id"
              v-bind:value="aggregation.id"
          >
            {{aggregation.title}}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-skeleton-loader
            class="full-width"
            type="button"
            height="69px"
            width="100%"
        ></v-skeleton-loader>
      </v-col>

      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <v-skeleton-loader
            class="full-width"
            type="button"
            height="69px"
            width="100%"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <div v-if="!dashboardData">
      <v-skeleton-loader
          class="mx-auto"
          type="image"
      ></v-skeleton-loader>

      <v-skeleton-loader
          class="mx-auto"
          type="image"
      ></v-skeleton-loader>
    </div>

    <div v-if="dashboardData">
      <div v-for="item in dashboardData.items" v-bind:key="item.id">
        <DashboardItemData
            :aggregateBy="aggregateBy"
            :dashboard="dashboard"
            :data="item"
        ></DashboardItemData>
      </div>
    </div>
  </div>
</template>

<script>
import {availableAggregations} from '@/services/filterStorage';
import DashboardItemData from '@/components/DashboardItemData'
export default {
  name: 'DashboardItem',
  components: {DashboardItemData},
  data() {
    return {
      availableAggregations: availableAggregations,
      aggregateBy: this.$filterStorage.getAggregateBy(),
      dashboard: null,
      dashboardData: null,
      from: this.$filterStorage.getDatesFrom(),
      fromOpen: false,
      to: this.$filterStorage.getDatesTo(),
      toOpen: false
    }
  },
  methods: {
    async askEndpoint () {
      const params = {
        from: this.from,
        to: this.to
      };
      if (this.dashboard.allowDateAggregation) {
        params.aggregateBy = this.aggregateBy
      }
      this.dashboardData = null
      this.dashboardData = await this.$axios.get(this.dashboard.endpoint, {params})
    },
    aggregateBySelected () {
      this.$filterStorage.setAggregateBy(this.aggregateBy)
      this.askEndpoint()
    },
    fromSelected () {
      this.fromOpen = false
      this.$filterStorage.setDatesFrom(this.from)
      this.askEndpoint()
    },
    toSelected () {
      this.toOpen = false
      this.$filterStorage.setDatesTo(this.to)
      this.askEndpoint()
    }
  },
  async created () {
    this.dashboard = await this.$dashboard.item(this.$route.params.id)
    await this.askEndpoint()
  }
}
</script>

<style scoped>

</style>