<template>
  <v-menu
      v-model="menu"
      bottom
      right
      transition="scale-transition"
      origin="top left"
  >
    <template v-slot:activator="{ on }">
      <v-chip
          class="ma-2"
          small
          text-color="white"
          v-on="on"
          :color="statusLocalized.color"
          :disabled="disabled"
      >
        {{ statusLocalized.title }}
        <v-icon
            right
            v-if="!disabled"
        >
          mdi-chevron-down
        </v-icon>
        <v-progress-circular
            v-else
            class="ml-2"
            color="white"
            indeterminate
            size="15"
            width="2"
        ></v-progress-circular>
      </v-chip>
    </template>
    <v-list>
      <v-subheader>Выберите статус заявки</v-subheader>
      <v-list-item
          v-for="s in statuses"
          :key="s.id"
          @click="$emit('change', s.id)"
      >
        <v-list-item-title v-text="s.title"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const statuses = [{id: 'fresh', title: 'Новая', color: 'green'}, {id: 'closed', title: 'Завершена', color: 'primary'}]
export default {
  name: 'SupportRequestStatus',
  computed: {
    statusLocalized: function () { return statuses.find(s => s.id === this.status) }
  },
  data () {
    return {
      menu: false,
      statuses: statuses
    }
  },
  props: {
    disabled: {
      required: true,
      type: Boolean
    },
    status: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>