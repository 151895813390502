<template>
  <v-app-bar v-if="isUnauthorized"
             app
             class="resizable"
             clipped-left
             color="primary"
             dark
  >

    <v-toolbar-title>Аналитика</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn outlined href="/api/auth/start?redirectUri=/">
      Войти
    </v-btn>
  </v-app-bar>
</template>

<script>
import {getters} from '@/stores/user';

export default {
  name: 'Header',
  computed: {
    ...getters
  }
}
</script>

<style scoped>

</style>
