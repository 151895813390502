export const apiPrefix = '/api/support'

export const limitListItems = 50

export const attachmentTypes = Object.freeze({
    audio: {id: 'audio', title: 'Аудиозапись'},
    doc: {id: 'doc', title: 'Документ'},
    photo: {id: 'photo', title: 'Фотография'},
    video: {id: 'video', title: 'Видеозапись'}
})

export const stealSessionTypes = Object.freeze({
    prod: {id: 'prod', title: 'Прод', icon: 'mdi-incognito'},
    stage: {id: 'stage', title: 'Стейдж', icon: 'mdi-incognito'}
});

export const subscriptionTypes = Object.freeze({
    'vz_personal' : {id: 'vz_personal', title: 'ВЗ персональная'},
    'vz_group' : {id: 'vz_group', title: 'ВЗ групповая'},
    'vz_site' : {id: 'vz_site', title: 'ВЗ виджет'},
    'vz_ad' : {id: 'vz_ad', title: 'ВЗ реклама'},
    'sp_org' : {id: 'sp_org', title: 'СПП органайзер'},
    'sp_lk' : {id: 'sp_lk', title: 'СПП ЛК'},
    'sp_store' : {id: 'sp_store', title: 'СПП магазин'},
    'sp_ultimate' : {id: 'sp_ultimate', title: 'СПП все включено'},
    'sp_soft' : {id: 'sp_soft', title: 'СП Софт'},
    'posred' : {id: 'posred', title: 'Посредник.онлайн'}
});