import axios from 'axios';
import Vue from 'vue'
import StoragePlugin from 'vue-web-storage'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify';
import {dashboardService} from '@/services/dashboard';
import {filterStorageService} from '@/services/filterStorage';
import {wsService} from '@/services/ws';
import {actions as sourceActions} from '@/stores/sources';
import {actions as tagsActions} from '@/stores/tags';
import {actions as userActions} from '@/stores/user';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.interceptors.response.use(function (response) {
  return response.data.data
}, function (error) {
  if ((error.response && (error.response.data.code === 401 || error.response.data.code === 403) && window.location.hash.length > 2)) {
    window.location = '/'
  }
  return Promise.reject(error.response ? error.response.data : error)
})

Vue.filter('date', function (value) {
    if (value) {
        return new Date(value).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        })
    }
})

Vue.filter('wordend', function (value, words) {
    return `${value}
    ${words[((value % 100 > 10 && value % 100 < 15) || value % 10 > 4 || value % 10 === 0) ? 2 : (value % 10 === 1) ? 0 : 1]}`;
})

Vue.use(StoragePlugin, {
  prefix: 'app.sph.analytics.'
})

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$dashboard = dashboardService
Vue.prototype.$filterStorage = filterStorageService
Vue.prototype.$ws = wsService

new Vue({
  router,
  vuetify,
  render: h => h(App),
  beforeCreate () {
      userActions.askData()
          .then(() => {
              if (window.location.hash === '#/') {
                  this.$router.replace('dashboards')
              }
              sourceActions.askData()
              tagsActions.askList()
              this.$ws.connect()
          })
          .catch(() => {
              if (window.location.hash !== '#/') {
                  this.$router.replace('/')
              }
          })
  }
}).$mount('#app')
