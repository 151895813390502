<template>
  <v-dialog
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      v-model="opened"
  >
    <v-form
        @submit="submit"
        ref="form"
        v-model="valid"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-toolbar-title>
            <slot name="title">
              Редактор тэгов
            </slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot name="body">
            <v-list>
              <v-list-item
                  v-for="(item, idx) in tags"
                  v-bind:key="idx"
              >
                <v-list-item-title>
                  <v-row>
                    <v-col>
                      <v-text-field
                          label="Название тэга"
                          v-model="item.title"
                          :counter="50"
                          :rules="titleRules"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <v-select
                          class="color-select"
                          label="Цвет"
                          v-model="item.color"
                          :items="colors"
                          :rules="colorRules"
                      >
                        <template
                            v-slot:selection="{item}"
                        >
                          <v-chip
                              class="color-selected"
                              :color="item"
                          >
                          </v-chip>
                        </template>
                        <template
                            v-slot:item="{ item, on }"
                        >
                          <v-list-item
                              :style="{'background-color': item}"
                              v-on="on"
                          >
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-list-item-title>

                <v-list-item-action>
                  <v-btn
                      color="error"
                      icon
                      @click="deleteTag(idx)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-btn
                color="primary"
                outlined
                @click="createTag"
            >
              <v-icon
                  left
              >
                mdi-plus
              </v-icon>
              Добавить тэг
            </v-btn>

            <Info
                class="mt-4"
                v-if="error"
                type="error"
                :message="error"
            ></Info>
          </slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="close()"
          >
            Отмена
          </v-btn>
          <v-btn
              color="success"
              text
              type="submit"
              :loading="updateProcess"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {actions, getters} from '@/stores/tags';
import Info from "@/components/Info";

export default {
  name: 'TagEditor',
  components: {Info},
  computed: {
    colors: getters.colors,
    tags: () => JSON.parse(JSON.stringify(getters.list()))
  },
  data () {
    return {
      error: null,
      colorRules: [
        v => !!v || '',
      ],
      titleRules: [
        v => !!v || 'Введите название',
        v => v.length <= 50 || 'Не более 50 символов'
      ],
      updateProcess: false,
      valid: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    createTag () {
      this.tags.push({title: 'Новый тэг', color: this.colors[this.colors.length * Math.random() | 0]})
    },
    deleteTag (idx) {
      this.tags.splice(idx, 1)
    },
    submit () {
      this.error = null
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      this.updateProcess = true
      actions.update(this.tags)
        .then(rs => {
          this.$emit('close', rs)
        })
        .catch(err => {
          this.error = err.error.message
        })
        .finally(() => this.updateProcess = false)
    }
  },
  props: {
    opened: {
      required: true,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.color-select {
  width: 60px;
}
.color-selected {
  height: 24px;
}
</style>