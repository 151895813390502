<template>
  <v-bottom-navigation
    color="teal"
  >
    <v-list-item
        class="souce-list"
        color="primary"
        v-for="source in sources"
        :key="source.id"
        :to="{name: 'support', params: {id: source.id}}"
    >
      <v-badge
          class="badge"
          bordered
          left
          top
          offset-x="20"
          offset-y="17"
          :content="newSources[source.id]"
          :value="!!newSources[source.id]"
      >
        <v-list-item-avatar
            v-if="source.preview"
            class="preview ml-2 mr-2"
            size="30"
        >
          <v-img :src="source.preview"></v-img>
        </v-list-item-avatar>

        <v-list-item-icon
              v-else
              class="all-icon ml-2 mr-6"
          >
            <v-icon
                size="30"
            >
              mdi-account-group
            </v-icon>
          </v-list-item-icon>
      </v-badge>
    </v-list-item>

    <v-list-item-avatar
        class="ml-6"
    >
      <v-img
          v-bind:src="avatar"
      >
      </v-img>
    </v-list-item-avatar>
    <v-btn
        class="ml-0 mr-1"
        href="/api/auth/logout?redirectUri=/"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import {getters as countersGetters} from '@/stores/counters'
import {getters as sourceGetters} from '@/stores/sources';
import {getters as userGetters} from '@/stores/user';

export default {
  name: 'MenuMobile',
  computed: {
    ...countersGetters,
    ...sourceGetters,
    ...userGetters
  },
  data () {
    return {
      
    }
  }
}
</script>

<style>
.v-list-item.souce-list {
  padding: 0!important;
  flex: none!important;
}
.v-bottom-navigation .v-btn {
  padding: 0!important;
}
.all-icon {
  height: 20px!important;
  min-width: 20px!important;
  width: 20px!important;
}
.badge .v-badge__badge {
  font-size: 10px!important;
  height: 15px!important;
  min-width: 15px!important;
  padding: 3px 2px!important;
}
</style>

