<template>
  <div class="label">
    {{ label }}:
    <v-chip
        v-for="tag of list"
        v-bind:key="tag.id"
        class="ma-2"
        x-small
        text-color="white"
        :color="tag.color"
    >
      <v-checkbox
          class="tag-checkbox"
          dark
          hide-details
          left
          dense
          v-model="selectedTags"
          :value="tag.id"
          :ripple="false"
          @click="$emit('select-toggle', tag.id)"
      ></v-checkbox>
      {{ tag.title }}
    </v-chip>
  </div>
</template>

<script>
import {getters} from '@/stores/tags';

export default {
  name: 'TagSelector',
  computed: {
    ...getters
  },
  data () {
    return {
      selectedTags: JSON.parse(JSON.stringify(this.selected))
    }
  },
  props: {
    label: {
      required: true,
      type: String
    },
    selected: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss">
.label {
  font-size: 16px;
}
.tag-checkbox {
  margin: 0;
  padding: 0;
  .v-icon {
    font-size: 16px;
  }
  .v-input--selection-controls__input {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
}
</style>